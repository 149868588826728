import { Loading } from '@actinc/dls/components/Loading';
import { first } from 'lodash';
import React, { useMemo } from 'react';

import Redirect from '~/components/Redirect';
import SchoolSearch from '~/components/SchoolSearch';
import NAMES from '~/constants/names';
import { EDUCATION_PARTNER_HS_ID } from '~/constants/reports';
import { Role } from '~/constants/roles';
import { ROUTES } from '~/constants/routes';
import USER_TYPES from '~/constants/userTypes';
import extractQueryParams from '~/helpers/extractQueryParams';
import localCache from '~/helpers/localCache';
import { isPathEquivalent } from '~/helpers/path';
import sentryGraphqlRequestError from '~/helpers/SentryErrors/sentryGraphqlRequestError';
import useActiveUser from '~/hooks/useActiveUser';
import useDetailedPermissions from '~/hooks/useDetailedPermissions';
import useLandingPageRedirect from '~/hooks/useLandingPageRedirect';
import useQueryParam from '~/hooks/useQueryParam';
import useSchoolId from '~/hooks/useSchoolId';
import AppLayout from '~/layouts/AppLayout';

import { SchoolSearchContainer } from './styles';

const Page: React.FC = (): React.ReactElement<unknown> => {
  const me = useActiveUser();
  const schoolId = useSchoolId();
  const getDetailedPermissions = useDetailedPermissions();

  if (getDetailedPermissions.error) {
    sentryGraphqlRequestError({ error: getDetailedPermissions.error, level: 'error', variables: { schoolId } });
  }

  const detailedPermissions = getDetailedPermissions.data?.detailedPermissions;
  const userHsIds = detailedPermissions?.map(permission => permission.organization?.externalId);
  const hsId = (userHsIds && userHsIds[0]) ?? 'Mentor';

  const { loading, url } = useLandingPageRedirect(schoolId || hsId);
  const returnUrl = useQueryParam('returnUrl');

  const isSuperintendent = useMemo(() => detailedPermissions?.some(item => item.organization?.role === Role.SUPERINTENDENT), [detailedPermissions]);

  if (!schoolId && returnUrl && userHsIds?.length === 1) {
    localCache.set(NAMES.SCHOOL_ID, hsId);
  }

  if (getDetailedPermissions.loading || loading) {
    return <Loading />;
  }

  if (me?.type !== USER_TYPES.LDAP && isSuperintendent) {
    return <Redirect to={url} />;
  }

  if (((me?.type !== USER_TYPES.LDAP && userHsIds && userHsIds?.length <= 1) || schoolId) && (schoolId || hsId) !== EDUCATION_PARTNER_HS_ID) {
    if (!schoolId || returnUrl) {
      return <Redirect to={url} />;
    }

    return <Redirect params={{ returnUrl }} to={url} />;
  }

  if (schoolId === EDUCATION_PARTNER_HS_ID || hsId === EDUCATION_PARTNER_HS_ID) {
    return <Redirect to={url} />;
  }

  if (returnUrl) {
    const decodedUrl = Array.isArray(returnUrl) ? decodeURIComponent(first(returnUrl) as string) : decodeURIComponent(returnUrl);
    const queryParams = extractQueryParams(decodedUrl);
    const contactId = queryParams ? queryParams.contactId : undefined;

    if (isPathEquivalent(decodedUrl, ROUTES.IMPERSONATE) && contactId) {
      return <Redirect params={{ contactId }} to={ROUTES.IMPERSONATE} />;
    }
  }

  return (
    <AppLayout>
      <SchoolSearchContainer>
        <SchoolSearch />
      </SchoolSearchContainer>
    </AppLayout>
  );
};

export default Page;
